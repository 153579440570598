.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #cacaca;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(58, 58, 58);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-container {
  display: flex;
  justify-content: center;
}

.App-center {
  width: 75%;
}


.App-typed > .App-cursor{
  border-right: 0.06em solid #fff;
  animation: blinker 1s step-start infinite;
}
@keyframes blinker {
  50% {
    border-right: 0.06em solid rgba(255, 255, 255, 0);
  }
}